const WorkboxLogo = ({ className, textColor = '#000000' }) => (
  <svg
    className={className}
    version='1.1'
    viewBox='0 0 788 135'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g transform='matrix(1.0808 0 0 1.0808 -25.754 -62.303)'>
      <g fill={textColor} strokeWidth='.88393'>
        <path d='m363.32 123.31q0 1.72 0.89 0.25l19.23-31.62a0.92 0.91-74 0 1 0.78-0.44h31.16q1.13 0 0.46 0.91l-23.45 31.55a0.85 0.83 50.2 0 0-0.07 0.9l26.47 51.02a0.6 0.6-13.5 0 1-0.54 0.87h-32.01a2.21 2.2-11.1 0 1-2.04-1.36l-19.67-47.79q-1.2-2.92-1.19 0.25l0.15 47.5a1.43 1.41 1 0 1-1.49 1.42q-13.84-0.58-27.01-0.09a0.71 0.71-1.2 0 1-0.74-0.71v-114.41a0.81 0.81-45 0 1 0.81-0.81h27.67a0.76 0.75 0.4 0 1 0.76 0.76z' />
        <path d='m489.76 94.21q9.2-4.74 18.49-5.21 20.31-1.03 34.19 12.56 8.3 8.13 11.16 20.37 3.8 16.23-2.06 30.66-3.95 9.74-11.34 16.21-9.88 8.66-23.94 10.29-15.84 1.84-30.66-8.04a1.01 0.83-60.4 0 0-1.29 0.49l-2.27 4.55a0.92 0.67 8.9 0 1-0.78 0.4l-20.33 0.19a0.42 0.41-0.7 0 1-0.43-0.41v-114.67a0.85 0.85 0.2 0 1 0.85-0.85l27.19 0.06a0.72 0.71 90 0 1 0.71 0.72l0.06 32.41a0.31 0.31-13.7 0 0 0.45 0.27zm16.678 59.32a19.42 19.05 90.3 0 0 19.151-19.32 19.42 19.05 90.3 0 0-18.948-19.519 19.42 19.05 90.3 0 0-19.151 19.32 19.42 19.05 90.3 0 0 18.948 19.519z' />
        <path d='m241.81 99.69q15.86 13.1 15.55 34.81-0.37 24.96-20.67 37.69-8.24 5.16-18.02 6.56-9.36 1.35-18.82-0.23-12.38-2.06-21.91-9.96-14.51-12.01-15.31-32.07-1.13-27.89 21.93-41.43 6.42-3.76 14.23-5.22 7.29-1.35 14.96-0.98 15.88 0.76 28.06 10.83zm-31.937 53.52a19.12 18.44 90.2 0 0 18.507-19.056 19.12 18.44 90.2 0 0-18.373-19.184 19.12 18.44 90.2 0 0-18.507 19.056 19.12 18.44 90.2 0 0 18.373 19.184z' />
        <path d='m574.44 103.69q8.43-9.57 21.54-13.02 10.35-2.72 20.66-1.58 10.02 1.12 18.93 6.01 19.44 10.66 22.07 33.17 1.23 10.56-1.58 19.78-3.48 11.39-12.29 19.28-7.26 6.5-17.31 9.67-15.1 4.78-30.96 0.25-19.72-5.63-27.88-22.84-4.3-9.09-4.68-19.31-0.65-17.63 11.5-31.41zm36 49.52a19.12 18.44 90.3 0 0 18.54-19.023 19.12 18.44 90.3 0 0-18.34-19.216 19.12 18.44 90.3 0 0-18.54 19.023 19.12 18.44 90.3 0 0 18.34 19.216z' />
        <path d='m68.24 129.14q0.28 0.99 0.69 0.06l16.53-36.92a0.92 0.92 10.6 0 1 0.79-0.54q8.28-0.52 17.07 0.1a1.59 1.57 79.8 0 1 1.32 0.93l15.68 35.18q0.95 2.13 1.59-0.13l10.13-35.65a0.68 0.68 8 0 1 0.65-0.49l28.73-0.17a0.48 0.47-81.5 0 1 0.45 0.62l-26.76 83.82a0.8 0.8 9 0 1-0.76 0.55h-22.32a1.07 1.07-11.8 0 1-0.98-0.64l-15.84-36q-0.33-0.75-0.66 0l-15.75 35.79a1.42 1.41 11.7 0 1-1.3 0.85h-21.74a1.17 1.17 81.1 0 1-1.11-0.81l-26.56-83.42a0.78 0.76 81.4 0 1 0.73-1.01l28.24 0.17a0.68 0.68-8 0 1 0.65 0.49z' />
      </g>
      <path
        d='m296.47 110.95 0.25-18.73a0.98 0.94-89.7 0 1 0.94-0.97h25.78a0.56 0.56 0 0 1 0.56 0.56v25.62a0.57 0.57-45 0 1-0.57 0.57h-26.06a0.87 0.87-45 0 0-0.87 0.87v56.75a1.07 1.06 90 0 1-1.06 1.07l-25.92 0.06a0.76 0.76-89.6 0 1-0.76-0.77l0.17-63.8a0.51 0.5 0 0 1 0.51-0.5l26.5-0.24a0.54 0.5 0 0 0 0.53-0.49z'
        fill='#f1c20a'
        strokeWidth='.92527'
      />
      <path
        d='m702.17 112.44a0.44 0.44 45.4 0 0 0.76 0.01l12.25-20.51a0.93 0.92 15.6 0 1 0.79-0.44h30.67q0.6 0 0.27 0.5l-25.68 39.19a0.94 0.93 45.7 0 0-0.01 1.01l27.25 43.4q0.71 1.13-0.65 1.13l-30.51-0.05a0.91 0.81-12.4 0 1-0.75-0.42l-13.58-24.09q-0.46-0.82-0.93 0l-13.61 23.84a0.9 0.85 13.4 0 1-0.75 0.43l-30.85 0.24q-0.88 0.01-0.41-0.73l27.42-43.01a0.81 0.8-45.5 0 0 0-0.87l-25.28-39.59q-0.58-0.92 0.5-0.92l30.53-0.06a1.15 1.15-14.8 0 1 1 0.58z'
        fill={textColor}
        strokeWidth='.88393'
      />
    </g>
  </svg>
);

export default WorkboxLogo;
